$color2_solid: shade($primary-color, 20%);
$color2: rgba($primary-color, 0.8);
$color1: rgba($primary-color, 0.95);

.iconTile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 215px;
    height: 210px;
    text-align: center;
    border-radius: 10px;
    vertical-align: middle;
    background: linear-gradient(135deg, $color1 0%, $color1 50%, $color2 51%, $color2 100%);
    cursor: pointer;
    border: 2px solid #eee;
    margin: 1rem;
    transition: all 0.3 ease;
}

.iconTile:hover {
    opacity: 0.8;
}

.iconTile span {
    font-size: 65px;
    height: 100%;
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconTile h4 {
    padding-top: 10px;
    padding-bottom: 15px;
    font-size: 19px;
    color: white;
}

.iconTile:hover > i {
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
}

.iconTileContainer {
    display: grid;
    justify-items: center;
    justify-content: center;
    grid-template-rows: 240px;
    grid-template-columns: repeat(1, 225px);
    gap: 14px;
    padding-bottom: 80px;
}

@media all and (min-width: 650px) {
    .iconTileContainer {
        grid-template-columns: repeat(2, 225px);
    }
}

@media all and (min-width: 992px) {
    .iconTileContainer {
        grid-template-columns: repeat(3, 225px);
    }
}
