//Fonts
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: $gray-color;
}

::-webkit-scrollbar-thumb {
    background: #75c6fc;
}

::-webkit-scrollbar-thumb:hover {
    background: #0e82cd;
}

////text color
.ant-descriptions-view * {
    color: $text-color !important;
}

.ant-descriptions * {
    color: $text-color !important;
}

.ant-select-selector *,
.ant-select-arrow {
    color: $text-color !important;
}

.ant-select-selection-search-input {
    color: $text-color !important;
}

th {
    color: $text-color !important;
}

.ant-btn-secondary {
    color: $text-color !important;
}

.ant-form-item {
    color: $text-color !important;
}

.text-color * {
    color: $text-color !important;
}

.ant-input-group * {
    color: $text-color !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.rdrDefinedRangesWrapper * {
    color: $text-color !important;
}
.rdrWeekDay {
    color: $text-color !important;
}

//Global
*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: Poppins, Arial, Helvetica, sans-serif !important;
}

.rs-footer {
    background-color: $footer-color;
    height: 48px !important;
}

li {
    list-style: none;
}

input {
    line-height: 35px !important;
}
//

.ant-color {
    color: white !important;
}
.ant-menu-size {
    font-size: 100px important;
}

//

//Logo
.logo-container {
    //width: 90px;
    //margin: 0 auto;
    margin-top: 10px;
    align-items: center !important;
}

.logo-container img {
    width: 55%;
    height: 55%;
    //margin-right: 10px !important;
    margin: auto;
    display: block;
    //margin-left: auto;
    //margin-right: auto;
    //padding-right: 20px !important;
}

//Logo
.logo-mobile {
    width: 90px;
    margin-top: 10px;
}

.logo-mobile img {
    width: 150%;
    height: 150%;
    object-position: center center;
    margin-left: 18px;
}

.mobile-header img {
    width: 70%;
    height: 70%;
    object-position: center center;
}

.rs-footer {
    height: 30px;
}

//Map
.leaflet-container {
    height: 250px;
    width: 100%;
}

.ant-btn-primary,
.ant-radio-button-wrapper-checked {
    background: $primary-color !important;
    border-color: transparent !important;
    color: $text-color !important;
}

.ant-btn-primary:hover {
    opacity: 0.8;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: transparent !important;
}

.ant-divider {
    padding: 0 !important;
}

.mobile-change-list {
    display: none;
}
.desktop-change-list {
    display: flex;
}

@media screen and (max-width: 800px) {
    .mobile-change-list {
        display: flex;
    }
    .desktop-change-list {
        display: none;
    }
}
.ant-switch-checked {
    background-color: $primary-color;
}

@media screen and (max-width: 450px) {
    .mobile-height-icon-tile-page {
        height: 1800px !important;
    }
}

.btn-login-form:hover {
    color: white !important;
    //background-color: white !important;
    border: 1px solid #fcab06 !important;
}
.btn-login-form {
    border: 1px solid #fcab06 !important;
}

.help-paragraph img {
    margin-bottom: 20px !important;
    margin-top: 6px !important;
    width: 98%;
    margin-left: 5px !important;
    margin-right: 5px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 4px 20px 0 rgba(0, 0, 0, 0.12);
}

.detail-info .ant-descriptions-row * {
    //padding-top: 5px !important;
    //padding-bottom: 5px !important;
    //padding-right: 5px !important;
    border: none !important;
}
.detail-info .ant-descriptions-row svg {
    padding: 0px !important;
}
.detail-info .ant-descriptions-item-label {
    font-weight: 500 !important;
}

.title {
    font-size: 22px;
    color: $primary-color;
    font-weight: bold;
    //padding-left: 10px;
    padding-top: 8px;
}

.detail-question .ant-descriptions-row * {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.print-detail-question .ant-descriptions-row * {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 14px;
}
.print-detail-question {
    border: 1px solid rgb(87, 87, 87);
}

.print-detail-question * {
    background: white !important;
}

.detail-storno .ant-descriptions-row th,
.detail-storno .ant-descriptions-row td {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    background-color: white !important;
    font-weight: 500 !important;
    border: none;
}

.detail-storno * {
    border: none !important;
    font-weight: 500 !important;
}
.detail-storno textarea.ant-input {
    border: 1px solid #d9d9d9 !important;
}

.black-icon {
    color: #000000 !important;
}

.detail-icon {
    padding-right: 20px !important;
    padding-top: 6px !important;
    color: #0e151a;
}

.column {
    float: left;
    width: 50%;
    padding: 0px;
    color: $text-color;
    height: auto;
    //border: 1px solid rgb(225, 226, 228)
}

.column-mobile {
    padding: 0px;
    color: $text-color;
}

.column div {
    padding-top: 4px;
    padding-left: 4px;
}

.column-mobile div {
    padding-left: 4px;
}

.mobile-card-div {
    display: flex;
    padding-top: 4px;
}

.big-column {
    float: left;
    width: 100%;
    padding: 5px;
    color: $text-color;
    height: auto;
    //border: 1px solid rgb(225, 226, 228)
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
    color: $text-color;
}

.mobile-card {
    display: none;
}

@media screen and (max-width: 550px) {
    .column {
        width: 100%;
        min-height: 120px;
    }
    .desktop-card {
        display: none;
    }
    .mobile-card {
        display: block;
        font-size: 14.5px !important;
        padding-top: 8px;
    }
}

.ant-alert-with-description {
    padding: 10px 10px 10px 64px;
}

.not-visible {
    display: none !important;
}

.print-size * {
    font-size: 40px !important;
}

.pdf-img img {
    width: 18% !important;
    height: 18% !important;
    margin-right: 0 !important;
    padding-top: 7px;
}
