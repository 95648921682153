/*
.__ant-layout-content {
  background: linear-gradient(
      90deg,
      rgba(255, 254, 233, 0.7) 0%,
      rgba(175, 242, 255, 0.7) 100%
    ),
    url("../images/head_bg.jpg");

  background-size: cover;
  background-position: center;
}
*/

//color of input filled by chrome
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
.ant-input-affix-wrapper {
    background-color: white !important;
}

.ant-input {
    color: black !important;
    height: 32px;
}

.sales-label {
    width: 100%;
    max-width: 300px !important;
    padding: 0 !important;
    margin-bottom: 10px !important;
    text-align: left !important;
}

.sales-label-text {
    padding: 0 0 5px !important;
    font-size: 14px;
    color: $text-color;
}

.ant-form-item-control-input-content {
    margin-bottom: 10px !important;
}

.ant-select-selector {
    color: $text-color;
}

.ant-table thead span {
    color: $text-color-secondary;
}

.ant-table-column-sorter * {
    color: #747474;
}

.ant-form-item-label > label {
    color: $text-color;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5px;
}

.form * {
    color: $text-color;
}

.ant-checkbox-wrapper {
    color: $text-color;
    padding: 0 0 5px !important;
}

.radio-button-responsive {
    width: 100%;
    max-width: 300px !important;
    margin: 0 !important;
    text-align: left !important;
}

.ant-page-header-content {
    overflow: visible !important;
}

.ant-collapse {
    background: transparent !important;
}

.ant-collapse-item {
    border-bottom: none !important;
    text-align: left;
}

.ant-form-item-label {
    text-align: left !important;
}

.ant-row {
    margin: 0 0 15px !important;
}

.ant-form-item-control {
    line-height: 1.5;
    text-align: left !important;
    //margin-top: 5px;
    max-width: 600px;
}

.ant-collapse-content-box {
    padding: 0 !important;
}

.left-padding {
    padding-left: 40px;
}

.filter-button {
    text-align: center;
    padding: 1px;
    margin: 0 0 15px !important;
    background-color: $gray-color !important;
    border: none;
    width: 100%;
    cursor: pointer;
}

.filter-button:hover h3 {
    font-weight: bold;
    color: #636262;
}

.detail-button {
    text-align: center;
    padding: 0;
    margin-bottom: 10px !important;
    background-color: $primary-color !important;
    border: none;
    width: 100%;
    cursor: pointer;
    padding-top: 8px;
    border-radius: 6px !important;
}

.detail-button:hover h3 {
    font-weight: bold;
    color: white;
}

//.filter-button h3 {
//padding-right: 40px;
//}

.multiple-button {
    padding: 1px;
    background-color: $gray-color !important;
    border: none;
    cursor: pointer;
}
.multiple-button:hover h3 {
    font-weight: bold;
    color: #636262;
}

.big-form-switch-div {
    background-color: white;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
}

.big-form-switch-button {
    background: $gray-color;
    padding: 4.5px;
    padding-bottom: 0;
    padding-top: 8px;
}

.big-form-switch-button button {
    padding-right: 40px;
    width: 220px;
    //width: 200px;
    //text-align: left;
}

.filter-route {
    text-align: center;
    padding: 2px;
    padding-top: 10px;
    margin-bottom: 10px !important;
    background-color: $gray-color !important;
    border: none;
    width: 100%;
    cursor: pointer;
    height: 44px;
}

.filter-route:hover {
    background-color: #a3a5aa !important;
}
.filter-route:hover h3 {
    font-weight: bold;
}

.form-question * {
    padding-bottom: 0px !important;
    padding-top: 0 !important;
}

.filter-button * {
    margin-top: 4px;
}

.left-column-calendar {
    display: inline-block;
}
.right-column-calendar {
    display: inline-block;
    padding-left: 15px;
}

.responsive-advanced-down {
    display: none;
}

.responsive-advanced-up {
    display: block;
}

.submit-center {
    display: block;
    width: 100%;
    max-width: 300px !important;
}

.date-range-picker-mobile * {
    display: none;
}

.ant-popover-inner-content {
    //padding: 4px 4px 4px !important;
    margin-left: 2px;
}

.rdrDefinedRangesWrapper {
    color: #2f2f2f;
    width: auto;
    border-right: none;
}

.rdrDateDisplayItemActive input {
    color: #2f2f2f;
}

.popup {
    width: 100%;
    background-color: $primary-color;
    border: none;
    padding-left: 35%;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white !important;
    cursor: pointer;
}

.popup:hover {
    opacity: 0.5;
}

@media (max-width: 600px) {
    .ant-calendar-range {
        width: 300px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .ant-calendar-range-part {
        width: 100%;
    }

    .ant-calendar-date-input-wrap {
        margin-left: 0px !important;
    }
}

@media screen and (min-width: 1090px) {
    .form {
        display: grid;
        grid-template-columns: 400px 2fr;
    }

    .form-part {
        display: grid;
        grid-template-columns: 1fr;
    }

    .ant-collapse-content-box {
        padding: 4px 16px !important;
    }

    .date-range-picker-desktop {
        display: inline-table;
        max-width: 180px;
        padding-left: 15px;
    }
}

@media screen and (min-width: 1200px) {
    .form-part {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.calendar {
    display: none;
    visibility: hidden;
    opacity: 0;
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5), -1px -1px 4px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.calendar.visible {
    display: block;
    visibility: visible;
    opacity: 1;
    z-index: 10000;
}

.full-date-picker-container {
    padding: 20px !important;
}

.date-picker-app-wrapper {
    position: relative;
    background-color: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 32px;
    padding: 0 11px;
    max-width: 300px;
}

.default-placeholder,
.default-placeholder .text {
    min-width: 90% !important;
    padding: 0 !important;
    line-height: 30px !important;
    border: none !important;
    color: black;
}

.default-placeholder .text {
    font-size: 2vw;
}

@media all and (min-width: 600px) {
    .default-placeholder .text {
        font-size: 0.8rem;
    }
}

.dates-container {
    font-size: 100% !important;
}

@media all and (max-width: 1250px) {
    .date-range-picker-desktop {
        display: none;
        padding-top: 0;
    }
    .date-range-picker-mobile * {
        display: flex;
    }
    .background-image {
        background-image: none;
    }
}

@media all and (max-width: 991px) {
    .ant-collapse-header {
        margin-left: -15px;
    }

    .left-padding {
        padding-left: 0;
    }

    .responsive-advanced-down {
        display: block;
    }

    .responsive-advanced-up {
        display: none;
    }
    .submit-center {
        margin-left: 0;
        width: 270px;
    }
}

.form-input-icon {
    padding-top: 9px;
    padding-right: 8px;
    color: #807e7e !important;
}

input[type="file"]::file-selector-button {
    border: 1px solid #d9d9d9;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: rgb(230, 230, 230);
    transition: 1s;
    //font-size: 13px;
    margin-right: 12px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 2px;
}

.left-pagination .ant-list-pagination {
    text-align: center;
}
