//Antd fixes

//Inputs
.ant-input-suffix {
    display: flex;
    align-items: center;
}

.ant-input-affix-wrapper {
    padding: 0;
    background: rgb(232, 240, 254);
    width: 350px;
    padding: 5px 10px;
}

//Btns
.btn-group {
    margin-top: 5px;
    margin-bottom: 15px;
    text-align: left;
}

.btn-group-height {
    height: auto;
    min-height: 150px;
}

.ant-btn {
    margin: 0 5px 5px 0;
}

.ant-btn:hover {
    background-color: $primary-color;
    color: black;
}

//Others
.anticon svg {
    display: block !important;
}

.ant-avatar {
    border: 1px solid $white-color;
    background: $heading-color !important;
}

.ant-alert {
    margin: 10px 0 !important;
}

.ant-popover-content {
    width: 200px;
}

.red .rdrDateDisplayWrapper {
    background-color: #dc143c;
}

.rdrStaticRangeSelected *,
.rdrNextPrevButton,
.rdrDateDisplayWrapper {
    background-color: $light-gray-color;
}
