/*
@keyframes loading0 {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}
@keyframes loading {
  0% {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.loading div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading div:nth-child(1) {
  border-color: #ffdc00;
}

.loading div:nth-child(2) {
  border-color: #000000;
  animation-delay: -0.5s;
}

.spinner {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.loading {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; // see note above 
}
.loading div {
  box-sizing: content-box;
}
*/

/*
@keyframes bouncyLoading {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.45, 0, 0.9, 0.55);
  }
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 108px);
    animation-timing-function: cubic-bezier(0, 0.45, 0.55, 0.9);
  }
  100% {
    transform: translate(0, 0);
  }
}

.bouncyLoading div {
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #ffdc00;
  left: 74px;
  top: 20px;
  animation: bouncyLoading 1s linear infinite;
}
.ball {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.bouncyLoading {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; // see note above
}
*/

/*
.loading-indicator {
  display: inline-block;
  position: fixed;
  width: 100vw;
  //height: 10vw;
  top: 45vh;
  background-color: #0082c9;
}
.loading-indicator:after {
  content: ' ';
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #ffdc00;
  border-color: #ffdc00 transparent #ffdc00 transparent;
  animation: loading-indicator 1.2s infinite;
  margin-left: 50vw;
}
@keyframes loading-indicator {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.loading-indicator-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 50;
}
*/

@import "./_vars.scss";

/* Loading */
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white-color;
}

.loading {
    width: 6rem;
    height: 6rem;
    margin: 0 auto;
    border-radius: 50%;
    border: 3px solid $primary-color;
    border-top-color: $link-color;
    animation: spinner 0.6s linear infinite;
}

.background-image {
    background-image: url("../images/empty-map.PNG");
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(100%);
    opacity: 50%;
}
