//Sider
.ant-layout-sider {
    max-width: 250px;
    min-width: 250px !important;
    display: flex;
    background: $heading-color !important;
    padding: 40px 11.5px;
    box-sizing: border-box;
    font-size: 1.2rem;
}

.ant-layout-sider-collapsed {
    max-width: 10px;
    min-width: 110px !important;
    display: flex;
}

.ant-layout-sider-collapsed img {
    max-width: 100%;
    min-width: 100%;
    padding-left: 10px;
}

//Nav
.nav {
    font-weight: 550;
    margin: 0 auto;
    padding: 40px 0px !important;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
}

.nav li {
    height: 50px;
    line-height: 50px;
}

.ant-menu-item-selected {
    background: white !important;
}

.nav li:not(.ant-menu-item-selected) a {
    color: white !important;
}

.ant-menu-item {
    margin: 0 !important;
    font-size: 16px;
    margin-bottom: 10px !important;
}

.ant-menu-item:hover {
    opacity: 0.7;
}
.ant-menu-item:hover * {
    color: #0e151a !important;
    font-weight: 600 !important;
}

.and-menu-size {
    font-size: 22px !important;
    text-shadow: $box-shadow-base !important;
}

//Mobile menu
.mobile-menu {
    background-color: $heading-color;
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    line-height: 100%;
    display: flex;
    flex-direction: column;
}

.mobile-header {
    position: absolute;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.5rem 1rem;
    background: $heading-color !important;
}

.mobile-nav {
    position: relative;
    top: 60px;
    width: 100%;
    margin: 0;
    background: $heading-color !important;
    font-size: 16px;
}

.btn-burger {
    padding: 5px;
    font-size: 1.5rem;
    border: none;
    outline: none;
    line-height: 100%;
    background: none;
    color: $white-color !important;
    cursor: pointer;
}

.ant-menu-inline span {
    font-size: 17px;
}

.ant-tabs-nav .ant-tabs-tab-active {
    color: $link-color;
    font-weight: 500;
}

.ant-tabs-ink-bar {
    background-color: $link-color !important;
}

.ant-tabs-nav-scroll {
    background-color: $gray-color;
    color: $text-color;
}
.ant-tabs-tab-prev,
.ant-tabs-tab-next {
    background-color: $gray-color;
    color: $text-color;
}
