.name-avatar {
    display: flex;
    object-position: top;
    padding-left: 10px;
    padding-top: 10px;
}

.name-avatar * {
    display: flex;
}

//Layout
.ant-layout {
    min-height: 100vh !important;
    display: flex;
    background-color: $white-color;
}

.ant-layout-header {
    background: none !important;
    height: 50px !important;
    display: flex;
    justify-content: space-between;
}

.site-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    background: $white-color;
    text-align: center;
}

.site-layout-content {
    display: flex;
    flex-direction: column;
    padding: 0px;
    min-height: 94vh !important;
}

.sider-toggler {
    padding-top: 14px;
}

.user-name-head {
    display: none;
}

.mobile-user-info {
    display: flex;
}

.mobile-user-info * {
    width: 100%;
}

.desktop-user-info {
    display: none;
}

.mobile-menu {
    padding-top: 5px;
}

.account-info-header {
    padding-top: 10px;
}

.site-layout-mobile {
    height: auto;
}
@media screen and (max-width: 400px) {
    .site-layout-mobile {
        min-height: 230vh !important;
    }
}
@media screen and (max-width: 500px) {
    .site-layout-mobile {
        min-height: 200vh !important;
    }
}

@media screen and (min-width: 600px) {
    .site-layout-content {
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 30px;
    }
    .user-name-head {
        display: flex;
    }

    .account-info-header {
        padding-top: 0px;
    }
}

.sider-toggler {
    display: flex !important;
    align-items: center;
    font-size: 1.2rem;
}

.login-container {
    max-width: 800px;
    width: 800px;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-box {
    position: relative;
    margin: 2rem auto;
    height: auto;
    width: auto;
    padding: 10px 80px;
    border-radius: 24px;
}

.password-form {
    position: relative;
    height: auto;
    width: 100%;
    //padding: 0 0px;
    border-radius: 3px;
}

.password-form * {
    margin-left: auto;
    margin-right: auto;
}

.blue-background {
    background-color: $primary-color;
}

.login-img {
    width: auto;
    height: auto;
}

.mobile-help {
    display: none;
}
.desktop-help {
    display: flex;
}
.desktop-help,
.mobile-help {
    border-color: $primary-color;
    font-size: 16px;
    color: $primary-color;
    //background-color: $primary-color;
    border-radius: 50%;
}
.desktop-help:hover,
.mobile-help:hover {
    color: white;
}

@media (max-width: 500px) {
    .login-box {
        padding: 10px 15px !important;
    }
    .login-img {
        width: 60% !important;
        height: 60% !important;
    }
}

@media (max-width: 600px) {
    .mobile-help {
        display: inline;
    }
    .desktop-help {
        display: none;
    }
    .ant-page-header-heading-extra {
        padding: 0;
    }
}

@media (max-width: 800px) {
    .login-box {
        padding: 10px 40px;
    }
}

.email-login {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
    margin-top: 20px;
    text-align: center;
}

.white-background {
    background-color: white;
    padding-top: 15px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
}

.u-form-group {
    width: 100%;
    margin: 0 15px 10px;
}

.login-container .logo-container {
    width: 300px;
}

.login-image {
    max-width: 40%;
    max-height: 40%;
    padding-bottom: 5px;
    margin-right: 120px;
}

.black-text-color * {
    color: #48a3d8;
}

//Ant Header
.ant-page-header {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: white !important;
    padding: 14px 30px 0px !important;
    padding-bottom: 12px !important;
}

.ant-modal-body {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.ant-page-header-half-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.ant-page-header-boxShadow {
    box-shadow: $box-shadow-base;
    border: none;
}

.ant-page-header-heading-title {
    color: $heading-color !important;
}

.ant-page-header-heading-extra {
    position: relative;
    display: block;
}

//Ant  Descriptions
th.ant-descriptions-item {
    padding-top: 15px;
}

.ant-descriptions,
.ant-descriptions-view {
    border-radius: 15px;
}

//.ant-descriptions-half-radius,
//.ant-descriptions-half-radius .ant-descriptions-view {
//border-top-left-radius: 0;
//border-top-right-radius: 0;
//}

.question-table td {
    border-right: none !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
    border-right: 1px solid #cdcdcd;
}

.description-boxShadow {
    box-shadow: $box-shadow-base;
}

.description-boxShadow .ant-descriptions-view {
    border-top: none !important;
}

.ant-page-header-heading-extra * {
    margin-right: 0;
}

/////////
.rc-footer-bottom-container {
    font-size: 14px !important;
    padding-top: 2px !important;
    padding-bottom: 10px !important;
    border-top: none !important;
    text-align: center;
}

.rc-footer-container {
    padding: 20px 0 2px !important;
}

.rc-footer-container * {
    font-family: Verdana, sans-serif;
    //margin-left: 5px !important;
}

.rc-footer-column {
    margin-bottom: 0 !important;
    font-size: 14px !important;
}

.rc-footer-column h2 {
    margin: 0 auto 8px !important;
    font-weight: 500;
    font-size: 16px !important;
    color: #fff;
}

.social-icons {
    font-size: 24px !important;
}

.contact-icons {
    font-size: 18px !important;
}

.small-desktop-version {
    display: none;
}
.big-desktop-version {
    display: flex;
}
.tablet-version {
    display: none;
}
@media screen and (max-width: 900px) {
    .small-desktop-version {
        display: flex;
    }
    .big-desktop-version {
        display: none;
    }
}
@media screen and (max-width: 770px) {
    .tablet-version {
        display: flex;
    }
    .small-desktop-version {
        display: none;
    }
    .big-desktop-version {
        display: none;
    }
    .top-padding {
        padding-top: 12px;
    }
    .bottom-padding {
        padding-bottom: 12px;
    }
    .rc-footer-bottom-container {
        border-top: 1px solid rgba(255, 255, 255, 0.25) !important;
    }
}

.help-image-desktop {
    display: flex;
}
.help-image-mobile {
    display: none;
}
@media screen and (max-width: 500px) {
    .help-image-desktop {
        display: none;
    }
    .help-image-mobile {
        display: flex;
    }
}
