//Ant table
.ant-table-wrapper {
    //margin-bottom: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: $box-shadow-base;
    width: auto;
    background: white !important;
}

.ant-table {
    background: white !important;
    padding: 0 30px 10px !important;
    //margin-top: 16px;
}

.ant-pagination-total-text {
    padding-right: 5px;
}

.ant-table thead > tr > th,
.ant-table tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
    padding: 12px 13px;
    color: $text-color;
}

.ant-select-selector * {
    color: $text-color;
}

.ant-table th {
    border: 1.5px solid #f4f4f4;
    background: #68a9df !important;
    padding: 18px 13px !important;
    font-weight: bold !important;
}

.ant-table th * {
    color: $text-color !important;
}

.ant-table-thead > tr > th {
    color: $gray-color;
}

.tableClass tr:nth-child(even) {
    background-color: $light-gray-color;
}

.flex {
    display: grid;
}

@media screen and (min-width: 900px) {
    .flex {
        display: flex;
    }
}

.custom-filter-dropdown label {
    display: block !important;
}

//Modify list text and background color
.ant-table-tbody > tr > th {
    color: white;
    background: #3071b9 !important;
}

.ant-pagination {
    margin-right: 22px !important;
    //display: none;
}

.new-pagination {
    display: flex !important;
}

.ant-table-body {
    overflow-x: auto !important;
}

.ant-table-row:nth-of-type(2n) {
    background: #f9fbfc;
}

.ant-table-row:nth-of-type(2n) button {
    background: #ffffff;
}

.ant-table-row:nth-of-type(2n + 1) button {
    background: #f9fbfc;
}

.form-question {
    background: #ffffff;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 8px;
}

.change-columns {
    background: #ffffff;
}

.change-columns .ant-select-selector * {
    height: 20px !important;
}

.ant-select-multiple .ant-select-selection-item {
    background-color: #e1e2e4;
    //padding: 10px;
}

.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn-disabled:focus,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn-disabled:active,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn-disabled.active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
    color: #797979 !important;
    background-color: #dcdcdc !important;
}

.ant-pagination {
    margin-top: 0 !important;
}

.ant-table-footer {
    padding-bottom: 0 !important;
    display: flex;
}
.ant-pagination-total-text {
    color: $text-color !important;
}
.ant-table-pagination {
    display: block;
}

.pagination-display {
    display: flex;
    position: absolute;
    padding-bottom: 20px;
    right: 30px;
    display: flex;
}

.pagination-total {
    display: flex;
}

.small-pagination {
    display: none;
}
.big-pagination {
    display: flex;
}

@media screen and (max-width: 840px) {
    .pagination-display {
        display: inline-block !important;
        position: relative;
        right: 0;
    }
    .pagination-total {
        display: none;
    }
    .small-pagination {
        display: flex;
        padding-left: 12px;
    }
    .big-pagination {
        display: none;
    }
}

.ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: $light-gray-color;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
    border: 1px solid $gray-color;
}

.center-items div {
    margin-left: auto;
    margin-right: auto;
}

.ant-descriptions-item-label {
    font-weight: bold !important;
}

.ant-descriptions-item-label.question-table {
    width: 10% !important;
}

.route-button {
    width: 198px !important;
    background-color: #fcab06 !important;
    color: black !important;
    font-weight: bold;
    border: none;
    margin-top: 8px;
}
.route-button:hover {
    color: white !important;
    background-color: #fcab06;
    border: none;
}

.blue-text * {
    color: #48a3d8 !important;
    font-weight: bold;
}

.ant-table tbody > tr.ant-table-row-selected > td {
    background-color: #fcd788;
}

.yellow-button {
    background-color: $warning-color;
    color: $text-color !important;
    border: none;
}

.yellow-button:hover {
    color: white !important;
    background-color: $warning-color !important;
}
.yellow-button:focus {
    background-color: $warning-color !important;
}

.ant-dropdown-trigger {
    cursor: pointer;
}
.ant-dropdown-trigger:hover * {
    color: white !important;
}

.ant-table .ant-table-expanded-row-fixed {
    background-color: white !important;
}

th.ant-table-column-sort {
    background: rgba(38, 131, 207, 0.4) !important;
}

td.ant-table-column-sort {
    background: rgba(0, 0, 0, 0.08);
}

.cancelled-row {
    background-color: #e5b3b3 !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}

.ant-table tbody > tr.ant-table-row-selected:hover > td {
    background-color: #fcd788 !important;
}

.ant-descriptions-item-content.red {
    background-color: #ffc7c7 !important;
    border: 1px solid white !important;
}
.ant-descriptions-item-label.red {
    background-color: #cccccc !important;
    font-weight: normal !important;
    border: 1px solid white !important;
}

.ant-descriptions-item-content.green {
    background-color: #b2d8b2 !important;
    border: 1px solid white !important;
}
.ant-descriptions-item-label.green {
    background-color: #cccccc !important;
    font-weight: normal !important;
    border: 1px solid white !important;
}

.ant-descriptions-item-content.yellow {
    background-color: #f3e8ab !important;
    border: 1px solid white !important;
}
.ant-descriptions-item-label.yellow {
    background-color: #cccccc !important;
    font-weight: normal !important;
    border: 1px solid white !important;
}
